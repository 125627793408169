<template>
  <div class="timeTableManage">
    <el-tabs v-model="activeName">
      <el-tab-pane label="规则设置"
                   name="first">
        <el-steps align-center
                  class="marginTop"
                  :active="step">
          <el-step title="步骤1"
                   description="课程结构设置"></el-step>
          <el-step title="步骤2"
                   description="特殊课程结构设置"></el-step>
          <el-step title="步骤3"
                   description="换算规则设置"></el-step>
        </el-steps>

        <!-- 课程结构设置 -->
        <div v-if="step == 1"
             class="stepTwo">
          <div class="classConstructorTop">
            <div class="classConstructorTopTitle">节次数量</div>
            <div class="classConstructorTopContent">
              1 天 =<el-input v-model="input"
                        @input="courseNum"
                        class="inputMargin"
                        type="number"
                        placeholder="输入数字"></el-input>节课
            </div>
          </div>
          <div class="classConstructorBottom">
            <div class="classConstructorBottomTitle">具体节次设置时间</div>
            <div class="classConstructorBottomContent">
              <div>
                <div class="marginBottom"
                     v-for="(item, index) in TimetableStructureList"
                     :key="index">
                  <span class="marginRight">{{ item.courseTitle }}</span>
                  <el-time-picker value-format="HH:mm"
                                  @change="timeChange(item, index, $event)"
                                  is-range
                                  v-model="item.courseTimeList"
                                  range-separator="至"
                                  start-placeholder="开始时间"
                                  end-placeholder="结束时间"
                                  placeholder="选择时间范围">
                  </el-time-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="marginTop rightDiv">
            <!-- <el-button @click="lastStep(1)" class="" type="success"
              >上一步</el-button
            > -->
            <el-button @click="classConstructorConfirm"
                       class="rightConfirm"
                       type="primary">确定</el-button>
          </div>
        </div>
        <!-- 特殊课程结构设置 -->
        <div v-if="step == 2"
             class="specailCourseConstructor">
          <div class="specailDiv">
            <el-form ref="specailCourseFormRef"
                     :rules="specailCourseFormRules"
                     :model="specailCourseForm"
                     label-width="120px">
              <el-form-item class="isGroupCourse courseSelect"
                            prop="isOrNo"
                            label="课程名称">
                <el-input v-model="groupCourseObj.courseGroupData[0].name"></el-input>
                <!-- <el-select
                  @change="courseChange"
                  v-model="course"
                  placeholder="请选择课程"
                >
                  <el-option
                    v-for="item in gradeList2"
                    :disabled="item.disabled"
                    :key="item.id"
                    :label="item.gname"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
              </el-form-item>

              <div v-if="bottomVisible">
                <div v-for="(jtem, index) in groupCourseObj.courseGroupData"
                     :key="index"
                     class="groupCourseStyle">
                  <span style="margin-top: -24px">小组课</span>
                  <div>
                    <!-- 年级 -->
                    <el-form-item prop="grade"
                                  label="年级">
                      <el-select multiple
                                 @remove-tag="removeGroupGrade(jtem, $event)"
                                 @change="gradeChange(jtem, $event)"
                                 v-model="jtem.grade"
                                 placeholder="请选择年级">
                        <el-option v-for="item in gradeList2"
                                   :disabled="item.disabled"
                                   :key="item.id"
                                   :label="item.gname"
                                   :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item prop="startDay"
                                  label="开始星期">
                      <el-select clearable
                                 @clear="clearDay"
                                 @change="dayChange"
                                 v-model="jtem.isWeek"
                                 placeholder="请选择星期">
                        <el-option v-for="item in dayList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="startCourse"
                                  label="开始节次">
                      <el-select clearable
                                 @clear="clearStartCourse"
                                 @change="startCourseChange"
                                 v-model="jtem.startSort"
                                 placeholder="请选择开始节次">
                        <el-option :disabled="
                            item.value === 1
                          "
                                   v-for="item in courseNameList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="endCourse"
                                  label="结束节次">
                      <el-select clearable
                                 @change="endCourseChange"
                                 v-model="jtem.endSort"
                                 placeholder="请选择结束节次">
                        <el-option :disabled="item.disabled"
                                   v-for="item in courseNameList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <el-button @click="deleteGroupCourse(index)"
                             type="danger"
                             style="margin-left: 30px; margin-top: -24px"
                             class="center">删除</el-button>
                </div>
                <el-button @click="addGroupCourse"
                           type="text"
                           style="margin-left: 30px"
                           class="marginLeft">+添加</el-button>
              </div>
            </el-form>
            <div class="marginTop rightDiv">
              <el-button @click="lastStep(1)"
                         class=""
                         type="success">上一步</el-button>
              <el-button @click="specailCourseConstructorConfirm"
                         class="rightConfirm"
                         type="primary">确定</el-button>
            </div>
          </div>
        </div>
        <!-- 换算规则设置 -->
        <div v-if="step == 3"
             class="stepTwo conversionRuleSettings">
          <!-- 常规情况 -->
          <div class="classConstructorTop">
            <div class="classConstructorTopContent fD">
              <div class="topDiv">
                <el-input @input="
                    algorithmicRuleInputChange(
                      -1,
                      'algorithmicRuleOneObj',
                      $event
                    )
                  "
                          v-model="algorithmicRuleObj.algorithmicRuleOneObj.sectionNum"
                          @change="courseNum"
                          class="inputMargin"
                          type="number"
                          placeholder="输入数字"></el-input>节
                <el-select @change="typeSelectChange"
                           class="conversionRuleSelectType"
                           v-model="algorithmicRuleObj.algorithmicRuleOneObj.type"
                           placeholder="选择类型"
                           clearable>
                  <el-option v-for="item in getTeacherTypeList"
                             :key="item.id"
                             :label="item.value"
                             :value="item.id">
                  </el-option>
                </el-select>
                <span>=
                  <el-input disabled
                            v-model="algorithmicRuleObj.algorithmicRuleOneObj.hour"
                            @change="courseNum"
                            class="inputMargin"
                            type="number"
                            placeholder="输入数字"></el-input>课时
                </span>
              </div>

              <div class="bottomDiv">
                <div v-for="(
                    item, index
                  ) in algorithmicRuleObj.algorithmicRuleList"
                     :key="index"
                     class="algorithmicListStyle">
                  <el-input @input="
                      algorithmicRuleInputChange(
                        index,
                        'algorithmicRuleList',
                        $event
                      )
                    "
                            v-model="item.sectionNum"
                            @change="courseNum"
                            class="inputMargin"
                            type="number"
                            placeholder="输入数字"></el-input>节
                  <el-select class="conversionRuleSelectType"
                             v-model="item.type"
                             placeholder="选择类型"
                             clearable>
                    <el-option :disabled="item.flag"
                               v-for="item in getTeacherTypeList"
                               :key="item.id"
                               :label="item.value"
                               :value="item.id">
                    </el-option>
                  </el-select>
                  <span>=
                    <el-input v-model="item.hour"
                              @change="courseNum"
                              class="inputMargin"
                              type="number"
                              placeholder="输入数字"></el-input>课时
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="marginTop rightDiv">
            <el-button @click="lastStep(2)"
                       class=""
                       type="success">上一步</el-button>
            <el-button @click="algorithmicRuleConfirm"
                       class="rightConfirm"
                       type="primary">确定</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="全校课表"
                   name="second">
        <allSchoolTimetable @activeNameChange="activeName = 'second'"
                            @change="classesImmediately"></allSchoolTimetable>
      </el-tab-pane>

      <el-tab-pane label="小组课课表"
                   name="third">
        <commonSchedule groupSchedule
                        :isFresh="classesImmediatelyBoolean"></commonSchedule>
      </el-tab-pane>
      <el-tab-pane label="个训课课表"
                   name="forth">
        <commonSchedule trainingCoursesSchedule
                        :isFresh="classesImmediatelyBoolean"></commonSchedule>
      </el-tab-pane>
      <el-tab-pane label="课时统计表"
                   name="fifth">
        <classSchedule></classSchedule>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import allSchoolTimetable from "@/components/educationalTeaching/automaticCourseArrangementManagement/allSchoolTimetable.vue";
import commonSchedule from "@/components/educationalTeaching/automaticCourseArrangementManagement/commonSchedule.vue";
import classSchedule from "@/components/educationalTeaching/automaticCourseArrangementManagement/classSchedule.vue";

export default {
  name: "timeTableManage",
  components: { allSchoolTimetable, commonSchedule, classSchedule },
  data() {
    return {
      activeName: "first",
      page: { pageCount: 1, currentPage: 0 },
      courseList: [
        { section: "一年级1班", sectionIndex: "1", course: [] },
        { section: "一年级2班", sectionIndex: "2", course: [] },
        { section: "二年级1班", sectionIndex: "3", course: [] },
        { section: "二年级2班", sectionIndex: "4", course: [] },
        { section: "二年级3班", sectionIndex: "5", course: [] },
        { section: "三年级1班", sectionIndex: "6", course: [] },
        { section: "四年级1班", sectionIndex: "7", course: [] },
        { section: "五年级1班", sectionIndex: "8", course: [] },
      ],
      // 当前学期
      nowSemester: JSON.parse(sessionStorage.getItem("nowSemester")),
      // 学期列表
      termList: [],
      // 年纪列表
      gradeList: [],
      // 教学类型设置
      teacherTypeForm: {
        // 所有年级列表（单个年级列表，选中年级）
        allGradeList: [],
      },
      teachingTypeObj: {
        teachingTypeData: [],
      },
      // 步骤
      step: 1,
      // 课表结构列表
      // value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      input: "",
      disabled: false,
      max: 6,
      TimetableStructureList: [
        // {
        //     courseTitle:'',
        //     courseTimeList:'',
        // }
      ],
      timetableStructureObj: {
        settingData: [],
      },

      // 特殊课程结构(小组课)
      // 星期列表
      dayList: [
        { value: 1, label: "星期一", flag: false },
        { value: 2, label: "星期二", flag: false },
        { value: 3, label: "星期三", flag: false },
        { value: 4, label: "星期四", flag: false },
        { value: 5, label: "星期五", flag: false },
      ],
      // 节次列表
      courseNameList: [
        // {value:1,label:'第1节',disabled:false},
      ],
      // 节次列表
      // courseNameList:[],
      // 小组课对象(特殊课程结构)
      specailCourseForm: {
        isOrNo: 1,
        // 年级
        grade: "",
        startDay: "",
        startCourse: "",
        endCourse: "",
      },
      specailCourseFormRules: {
        isOrNo: [{ required: true, message: "请选择是或否", trigger: "blur" }],
        // 年级验证规则
        grade: [{ required: true, message: "请选择年级", trigger: "blur" }],
        startDay: [{ required: true, message: "请选择星期", trigger: "blur" }],
        startCourse: [
          { required: true, message: "请选择开始节次", trigger: "blur" },
        ],
        endCourse: [
          { required: true, message: "请选择结束节次", trigger: "blur" },
        ],
      },
      // 小组课提交表单
      specailCourseFormSubmitObj: {},
      // 显示或隐藏
      bottomVisible: true,

      // 换算规则设置
      algorithmicRuleTypeList: [
        { value: 1, label: "主教" },
        { value: 2, label: "辅教" },
        { value: 3, label: "其他" },
      ],
      // 换算规则对象
      algorithmicRuleObj: {
        // 换算规则第一行对象
        algorithmicRuleOneObj: {
          type: "",
          sectionNum: "",
          hour: "1",
        },
        // 常规情况换算规则列表
        algorithmicRuleList: [
          {
            type: "", //主教或辅教或其他
            sectionNum: "", //节次
            hour: "", //课时
          },
        ],
        // 其他情况换算规则列表
        otherAlgorithmicRuleList: [
          {
            type: "", //主教或辅教或其他
            sectionNum: "", //节次
            hour: "", //课时
          },
        ],
      },
      // 换算规则提交对象
      algorithmicRuleConfirmObj: {
        algorithmicRuleList: [
          // {
          //     type:1,
          //     sectionNum:1,
          //     hour:1,
          // }
        ],
      },
      deleteVisible: false,
      // 数量
      // num:1,
      // 小组课对象
      groupCourseObj: {
        courseGroupData: [
          {
            name: "",
            isGroup: 1,
            // 年级
            gradeData: [],
            // grade:"",
            isWeek: "",
            startSort: "",
            endSort: "",
          },
        ],
      },

      // 教学类型列表
      getTeacherTypeList: [],
      // 让后面的输入框内容也清空
      clearAfterTypeSelect: false,
      // 所有年级列表
      teachTypeAllGradeList: [],
      // 小组课年级列表
      gradeList2: "",
      course: "",
      classesImmediatelyBoolean: false,
    };
  },
  created() {
    // 得到学科列表
    this.getSubjectList(1);
    this.queryAllGradeList();

    // 得到教学类型列表
    this.getTeacherType();
    // 步骤3中得到节次列表
    if (this.step === 3) {
      this.queryCourse();
      this.queryAllGradeList();
    }

    console.log(this.$refs);
  },
  watch: {
    input(newValue) {
      if (newValue > 9) {
        this.input = "";
      }
    },
    num() {},
  },
  methods: {
    classesImmediately(bol) {
      this.classesImmediatelyBoolean = bol;
    },
    courseChange() {},
    // 添加小组课
    addGroupCourse() {
      let obj = {
        name: this.groupCourseObj.courseGroupData[0].name,
        isGroup: 1,
        // 年级
        // grade:"",
        gradeData: [],
        isWeek: "",
        startSort: "",
        endSort: "",
      };
      this.groupCourseObj.courseGroupData.push(obj);
    },
    // 删除小组课
    deleteGroupCourse(index) {
      this.groupCourseObj.courseGroupData.splice(index, 1);
    },
    getSubjectList(page) {
      this.$postJson(`/subject/hour/queryList`, {
        indexNo: page,
        rows: 10,
        schoolYearId: this.nowSemester,
      }).then((res) => {
        // console.log(res);
      });
    },
    // 查询所有年级列表
    queryAllGradeList() {
      this.$post(`/media/queryAllclasses.do`).then((res) => {
        this.teachTypeAllGradeList = res.data;
        this.gradeList2 = res.data;
        this.gradeList2.forEach((item) => {
          item.disabled = false;
        });
        this.getGradeList();
      });
    },
    // 教学类型设置
    getGradeList() {
      this.gradeList = [];
      this.teachTypeAllGradeList.forEach((item) => {
        let obj = {
          value: item.id,
          label: item.gname,
          disabled: false,
        };
        this.gradeList.push(obj);
      });

      let obj2 = {
        listOrder: this.gradeList,
        gradeName: [],
        domains: [{ value: "" }],
      };
      this.teacherTypeForm.allGradeList.push(obj2);
    },
    // 得到教学类型列表
    getTeacherType() {
      this.getTeacherTypeList = [];
      this.$get(`/dict/queryDict`).then((res) => {
        res.data.forEach((item) => {
          if (item.code == "teaching_type") {
            item.dictItem.forEach((jtem) => {
              jtem.flag = false;
              this.getTeacherTypeList.push(jtem);
            });
          }
        });
      });
    },
    // 删除表单
    removeDomain(index2, index) {
      this.teacherTypeForm.allGradeList[index].domains.splice(index2, 1);
    },
    // 增加类型
    addDomain(index) {
      this.teacherTypeForm.allGradeList[index].domains.push({
        value: "",
        key: Date.now(),
      });
    },
    // 添加年级(新增)
    addGrade() {
      let obj2 = {
        listOrder: this.gradeList,
        gradeName: [],
        domains: [{ value: "" }],
      };
      this.teacherTypeForm.allGradeList.push(obj2);
    },
    // 选中年级时
    selectGradeChange(index, eventList) {
      // console.log(eventList)
      let obj = this.teacherTypeForm.allGradeList[index];
      for (let i = 0; i < eventList.length; i++) {
        for (let j = 0; j < obj.listOrder.length; j++) {
          if (eventList[i] == obj.listOrder[j].value) {
            obj.listOrder[j].disabled = true;
          }
        }
      }
    },
    // 移除年级时
    removeGradeChange(index, event) {
      // console.log(index, event);
      let obj = this.teacherTypeForm.allGradeList[index];
      obj.listOrder.forEach((item) => {
        if (item.value == event) {
          item.disabled = false;
        }
      });
    },
    // 删除年级
    deleteGrade(index) {
      this.teacherTypeForm.allGradeList.splice(index, 1);
    },
    // 课程结构设置
    // 输入课程数量
    courseNum(value) {
      this.TimetableStructureList = [];
      // console.log(value);
      // 当前年
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      let currentDate = new Date().getDate();
      // console.log(currentYear,currentMonth,currentDate)

      if (value <= 9) {
        for (let i = 0; i < value; i++) {
          let obj = {
            courseTitle: `第${i + 1}节课`,
            courseTimeList: [
              this.getTime(
                new Date(currentYear, currentMonth, currentDate, 8, 30)
              ),
              this.getTime(
                new Date(currentYear, currentMonth, currentDate, 19, 0)
              ),
            ],
          };
          this.TimetableStructureList.push(obj);
        }
      }
    },
    // 时间改变时
    timeChange(item, index, eventList) {
      item.courseTimeList = eventList;
    },
    // 上一步
    lastStep(step) {
      this.step = step;
    },
    // 时间转换
    getTime(d) {
      // console.log(typeof d)
      let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return `${hour}:${minutes}`;
    },
    // 课程结构提交
    classConstructorConfirm() {
      let data;
      this.TimetableStructureList.forEach((item, index) => {
        data = {
          sort: index + 1,
          name: item.courseTitle,
          startTime: item.courseTimeList[0],
          endTime: item.courseTimeList[1],
        };
        this.timetableStructureObj.settingData.push(data);
      });
      this.$postJson(
        `/course/setting/saveCourseSettingTime`,
        this.timetableStructureObj
      )
        .then((res) => {
          // console.log(res);
          this.step = 2;
          this.queryAllGradeList();
          this.queryCourse();
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
        });
      this.timetableStructureObj.settingData = [];
    },
    // 查询课程
    queryCourse() {
      this.$get(`/course/setting/queryCourseSetting`).then((res) => {
        res.data.forEach((item) => {
          item.value = item.sort;
          item.label = item.name;
          item.disabled = false;
        });
        // console.log(res.data);
        this.courseNameList = res.data;
      });
    },
    // 特殊课程
    // 单选变化
    radioChange(value) {
      //// console.log(value);
      this.groupCourseObj.courseGroupData[0].isGroup = value;
      if (value == 1) {
        this.bottomVisible = true;
      } else {
        this.bottomVisible = false;
      }
    },
    // 小组课中年级变化
    gradeChange(jtem, value) {
      jtem.gradeData = [];
      value.forEach((item) => {
        this.gradeList2.forEach((mtem) => {
          if (item == mtem.id) {
            let obj = {
              gradeId: mtem.id,
              gradeName: mtem.gname,
            };
            jtem.gradeData.push(obj);
          }
        });
      });

      this.specailCourseForm.grade = value;
    },
    // 小组课中移除年级时
    removeGroupGrade(jtem, value) {
      console.log(jtem);
      console.log(value);
      console.log(this.gradeList2);

      this.gradeList2.forEach((item) => {
        if (item.id === value) {
          item.disabled = false;
        }
      });
    },
    // 星期变化
    dayChange(value) {
      this.specailCourseForm.startDay = value;
    },
    clearDay() {
      this.dayList.forEach((jtem) => {
        jtem.flag = false;
      });
      this.groupCourseObj.courseGroupData.forEach((item) => {
        this.dayList.forEach((jtem) => {
          if (item.isWeek === jtem.value) {
            jtem.flag = true;
          }
        });
      });
    },
    // 开始节次变化(让选中及选中前面的禁用)
    startCourseChange(value) {
      this.specailCourseForm.startCourse = value;
      let index;
      this.courseNameList.forEach((item) => {
        if (item.value == value) {
          index = this.courseNameList.indexOf(item);
        }
      });
      // 让选中前面的禁用
      for (let i = 0; i < index; i++) {
        this.courseNameList[i].disabled = true;
      }
      // 让选中以及后面的不禁用
      for (let j = this.courseNameList.length - 1; j > index - 1; j--) {
        this.courseNameList[j].disabled = false;
      }

      // 选中第2节或者第3节或者第4节，让第5节以及后面的禁用
      if (value == 2 || value == 3 || value == 4) {
        for (let i = this.courseNameList.length - 1; i > 3; i--) {
          this.courseNameList[i].disabled = true;
        }
      }
      // 选中第5节，只能选中第6节，第6节后面的节次禁用
      // if (value == 5) {
      //   for (let i = this.courseNameList.length - 1; i > 5; i--) {
      //     this.courseNameList[i].disabled = true;
      //   }
      // }
    },
    // 清空开始节次
    clearStartCourse(value) {
      this.courseNameList.forEach((item) => {
        item.disabled = false;
      });
    },
    // 结束节次变化
    endCourseChange(value) {
      this.specailCourseForm.endCourse = value;
    },
    // 特殊课程结构确认
    specailCourseConstructorConfirm() {
      if (this.bottomVisible) {
        console.log(this.groupCourseObj);
        //  return
        let arr = [];
        this.groupCourseObj.courseGroupData.forEach((item) => {
          let obj = {
            name: item.name,
            isGroup: 1,
            gradeData: item.gradeData,
            isWeek: item.isWeek,
            startSort: item.startSort,
            endSort: item.endSort,
          };
          arr.push(obj);
        });
        let groupCourseObj = {
          courseGroupData: arr,
        };
        console.log(groupCourseObj.courseGroupData);
        // return
        this.$refs.specailCourseFormRef.validate((validate) => {
          if (validate) {
            this.$postJson(
              `/course/group/saveCourseGroup`,
              groupCourseObj
            ).then((res) => {
              // console.log(res);
              this.step = 3;
            });
          }
        });
      } else {
        this.step = 3;
      }
    },
    // 类型选种值变化
    typeSelectChange(value) {
      this.getTeacherTypeList.forEach((item) => {
        if (item.id == value) {
          item.flag = true;
        } else {
          item.flag = false;
        }
      });
    },
    // 添加换算规则
    addAlgorithmicRuleObj(name) {
      let obj = {
        type: "",
        sectionNum: "",
        hour: "",
      };
      this.algorithmicRuleObj[name].push(obj);
    },
    // 删除换算规则
    deleteAlgorithmicRuleRow(index, name) {
      this.algorithmicRuleObj[name].splice(index, 1);
    },
    // 换算规则输入框改变
    algorithmicRuleInputChange(index, name, value) {
      if (index === -1) {
        if (value > 10) {
          this.algorithmicRuleObj[name].sectionNum = "";
        }
      } else {
        if (value > 10) {
          this.algorithmicRuleObj[name][index].sectionNum = "";
        }
      }
    },
    // 换算规则提交
    algorithmicRuleConfirm() {
      let data = {
        algorithmicRuleList: [
          this.algorithmicRuleObj.algorithmicRuleOneObj,
          ...this.algorithmicRuleObj.algorithmicRuleList,
        ],
      };
      this.$postJson(`/algorithmic/rule/save`, data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "规则设置成功！",
          });
          this.activeName = "second";
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: err.message,
          });
        });
    },
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.addClassScheduleVisible = false;
        this.teacherSchedule();
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    exportFile() {
      const that = this;
      this.$postFile("/base/downloadCourseTableTemplate.do", {})
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "课表模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.groupCourseStyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.marginLeft {
  margin-left: 20px;
}

.marginRight {
  margin-right: 20px;
}

.marginTop {
  margin-top: 20px;
}

.marginBottom {
  margin-bottom: 20px;
}

.flexStart {
  display: flex;
  align-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
}

.width {
  // width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightDiv {
  width: 700px;
  display: flex;
  // flex-direction: column;
  justify-content: center;
}

.rightConfirm {
  // width: 100%;
  align-self: flex-end;
  margin-left: 60px;
}

.center {
  align-self: center;
  margin-top: -60px;
}

.contentMarginTop {
  margin-top: 80px;
}

.stepTwo {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.classConstructorTop {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.classConstructorTopTitle {
  background-color: #eee;
  padding: 10px;
}

.classConstructorTopContent {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px;
}

.classConstructorBottom {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.classConstructorBottomTitle {
  background-color: #eee;
  padding: 10px;
}

.classConstructorBottomContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px;
}

.inputMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.specailCourseConstructor {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.specailDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specailDiv .isGroupCourse /deep/ .el-form-item__label {
  width: 178px !important;
}

.courseSelect {
  margin-bottom: 40px;
}

// 换算规则样式
.conversionRuleSettings {
}

.fD {
  flex-direction: column;
}

.topDiv {
}

.bottomDiv {
  margin-top: 60px;
  margin-bottom: 30px;
}

.conversionRuleSelectType {
  margin-left: 10px;
  margin-right: 10px;
}

.algorithmicListStyle {
  margin-bottom: 30px;
}

// 其他情况样式
.classConstructorBottomContentDiv {
  flex-direction: column;
}
</style>
