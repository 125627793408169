<template>
  <div>
    <el-button
      v-if="trainingCoursesSchedule"
      @click="addTrainingCourse"
      style="margin-bottom: 15px"
      type="primary"
      >添加个训课</el-button
    >
    <el-button @click="exportCourse" style="margin-bottom: 15px" type="primary"
      >导出课表</el-button
    >
    <table border="1" cellspacing="0" cellpadding="25" width="1500px">
      <thead>
        <tr v-if="trainingCoursesSchedule" style="background-color: #e3eaf7">
          <th colspan="7">
            <div class="weekAndSection">
              <div class="weekRowStyle">
                <i
                  @click="arrowClick(-1, 'week')"
                  class="el-icon-arrow-left iconArrowStyle"
                ></i>
                <span style="font-size: 20px">{{ weekName }}</span>
                <i
                  @click="arrowClick(1, 'week')"
                  class="el-icon-arrow-right iconArrowStyle"
                ></i>
              </div>

              <div class="weekRowStyle">
                <i
                  @click="arrowClick(-1, 'sort')"
                  class="el-icon-arrow-left iconArrowStyle"
                ></i>
                <span style="font-size: 20px">{{ sortName }}</span>
                <i
                  @click="arrowClick(1, 'sort')"
                  class="el-icon-arrow-right iconArrowStyle"
                ></i>
              </div>
            </div>
          </th>
        </tr>

        <tr style="background-color: #ecf5ff">
          <th v-if="trainingCoursesSchedule">序号</th>
          <!-- <th v-if="groupSchedule">星期</th>
          <th v-if="groupSchedule">节次</th> -->
          <th>教室</th>
          <th>学科</th>
          <th>教师</th>
          <th>学生</th>
          <th>人数</th>
          <th>备注</th>
        </tr>
      </thead>
      <tbody v-if="groupSchedule">
        <tr
          align="center"
          v-for="(item, index) in groupScheduleList"
          :key="item.id"
        >
          <!-- <td class="classroom">
            {{ weekAndSortName('weekName',item.isWeek) }}
          </td>
          <td class="classroom">
            {{ weekAndSortName('sortName',item.sort) }}
          </td> -->
          <td
            :tabindex="index"
            @click="clickShowDialog('classroomForm', item)"
            class="classroom dialogHover"
          >
            {{ item.classroomName }}
          </td>
          <td
            :tabindex="index"
            @click="clickShowDialog('courseForm', item)"
            class="subject dialogHover"
          >
            {{ item.courseName }}
          </td>
          <td
            :tabindex="index"
            @click="clickShowDialog('teacherForm', item)"
            class="teacher dialogHover"
          >
            <div>主教：{{ item.mainTeachName }}</div>
            <div>辅教：{{ item.assistName }}</div>
          </td>
          <td
            :tabindex="index"
            @click="clickShowDialog('studentForm', item)"
            class="student dialogHover"
          >
            <!-- <div>姓名：{{ item.studentGroupName }}</div>
            <div>班级：{{ item.className }}</div> -->
            <div>{{ item.studentGroupName }}</div>
          </td>
          <td>{{ item.studentGroup.split(",").length }}</td>
          <td
            :tabindex="index"
            @click="clickShowDialog('remarkForm', item)"
            class="remark dialogHover"
          >
            {{ item.remark }}
          </td>
        </tr>
      </tbody>
      <tbody v-if="trainingCoursesSchedule">
        <tr
          align="center"
          v-for="(item, index) in trainingCoursesList"
          :key="index"
        >
          <td v-if="trainingCoursesSchedule">{{ index + 1 }}</td>
          <td
            :tabindex="index"
            @click="clickShowDialog('classroomForm', item)"
            class="classroom dialogHover"
          >
            {{ item.classroomName }}
          </td>
          <td
            :tabindex="index"
            @click="clickShowDialog('courseForm', item)"
            class="subject dialogHover"
          >
            {{ item.courseName }}
          </td>
          <td
            :tabindex="index"
            @click="clickShowDialog('teacherForm', item)"
            class="teacher dialogHover"
          >
            <div>主教：{{ item.mainTeachName }}</div>
            <!-- <div>辅教：{{ item.assistName }}</div> -->
          </td>
          <td
            :tabindex="index"
            @click="clickShowDialog('studentForm', item)"
            class="student dialogHover"
          >
            <div>{{ item.studentGroupName }}</div>
            <!-- <div>姓名：{{ item.studentGroupName }}</div>
            <div>班级：{{ item.className }}</div> -->
          </td>
          <td>{{ item.studentGroup.split(",").length }}</td>

          <td
            :tabindex="index"
            @click="clickShowDialog('remarkForm', item)"
            class="remark dialogHover"
          >
            {{ item.remark }}
          </td>
        </tr>
      </tbody>
    </table>

    <el-dialog
      title="添加"
      :visible.sync="trainingCourseFormDialogVisible"
      width="30%"
    >
      <el-form
        ref="trainingCourseFormRef"
        :model="trainingCourseForm"
        label-width="80px"
      >
        <el-form-item label="星期：">
          <el-select
            v-model="trainingCourseForm.isWeek"
            clearable
            filterable
            placeholder="请选择星期"
          >
            <el-option
              v-for="item in weekList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="节次：">
          <el-select
            v-model="trainingCourseForm.sort"
            clearable
            filterable
            placeholder="请选择节次"
          >
            <el-option
              v-for="item in sortList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称：">
          <el-select
            v-model="trainingCourseForm.name"
            clearable
            filterable
            placeholder="请选择名称"
          >
            <el-option
              v-for="item in courseHourList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="trainingCourseFormDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submitTrainingCourseTable"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="编辑"
      :visible.sync="classroomFormDialogVisible"
      width="30%"
    >
      <el-form ref="classroomFormRef" :model="classroomForm" label-width="80px">
        <el-form-item label="教室：">
          <el-select
            v-model="classroomForm.name"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in classroomList"
              :key="item.id"
              :label="item.floor_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classroomFormDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('classroomForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="编辑"
      :visible.sync="teacherFormDialogVisible"
      width="30%"
    >
      <el-form ref="teacherFormRef" :model="teacherForm" label-width="80px">
        <el-form-item label="主教：">
          <el-select
            v-model="teacherForm.mainTeachName"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="groupSchedule" label="辅教：">
          <el-select
            multiple
            v-model="teacherForm.assistName"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="teacherFormDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('teacherForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="编辑" :visible.sync="courseFormDialogVisible" width="30%">
      <el-form ref="courseFormRef" :model="courseForm" label-width="80px">
        <el-form-item label="学科：">
          <el-select
            v-model="courseForm.subject"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in courseList"
              :key="item.id"
              :label="item.course"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="courseFormDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('courseForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="编辑"
      :visible.sync="studentFormDialogVisible"
      width="30%"
    >
      <el-form ref="studentFormRef" :model="studentForm" label-width="80px">
        <el-form-item label="学生：">
          <el-select
            multiple
            v-model="studentForm.student"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in studentList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="studentFormDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('studentForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="编辑" :visible.sync="remarkFormDialogVisible" width="30%">
      <el-form ref="studentFormRef" :model="remarkForm" label-width="80px">
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="remarkForm.remark"
            placeholder="请填写备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="remarkFormDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('remarkForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "groupSchedule",
  props: {
    trainingCoursesSchedule: Boolean,
    groupSchedule: Boolean,
    // 是否刷新
    isFresh:Boolean
  },
  data() {
    return {
      fresh:false,
      groupScheduleList: [],
      trainingCoursesList: [{}],
      classroomFormDialogVisible: false,
      teacherFormDialogVisible: false,
      courseFormDialogVisible: false,
      studentFormDialogVisible: false,
      remarkFormDialogVisible: false,
      classroomForm: {
        id: "",
        isWeek: "",
        sort: "",
        name: "",
      },
      teacherForm: {
        id: "",
        isWeek: "",
        sort: "",
        mainTeachName: "",
        assistName: "",
      },
      courseForm: {
        id: "",
        isWeek: "",
        sort: "",
        subject: "",
      },
      studentForm: {
        id: "",
        isWeek: "",
        sort: "",
        student: "",
      },
      remarkForm: {
        remark: "",
      },
      courseList: [],
      teacherList: [],
      studentList: [],
      classroomList: [],
      trainingCourseForm: {
        isWeek: "",
        type: "",
        sort: "",
        courseHourId: "",
        name: "",
      },
      trainingCourseFormDialogVisible: false,
      weekList: [
        { id: 1, value: "星期一" },
        { id: 2, value: "星期二" },
        { id: 3, value: "星期三" },
        { id: 4, value: "星期四" },
        { id: 5, value: "星期五" },
      ],
      typeList: [
        { id: 0, value: "基础课" },
        { id: 1, value: "小组课" },
        { id: 2, value: "个训课" },
      ],
      sortList: [
        { id: 1, value: "第一节" },
        { id: 2, value: "第二节" },
        { id: 3, value: "第三节" },
        { id: 4, value: "第四节" },
        { id: 5, value: "第五节" },
        { id: 6, value: "第六节" },
      ],
      courseHourList: [],
      week: 1,
      weekName: "星期一",
      sort: 1,
      sortName: "第一节",
      trainingCoursesList: [],
    };
  },
  created() {
    this.getGroupSchedule();

    this.loadAllTeacher();
    this.loadClassroom();
    this.loadStudent();
    this.queryCourse();

    this.queryCourseHourList();

    // if(this.isFresh){
    //   this.getGroupSchedule();
    // }
  },
  watch:{
    isFresh(newValue,oldValue){
      // this.fresh = newValue
      this.getGroupSchedule();
      this.isFresh = false
    }
  },
  computed: {
    weekAndSortName() {
      let weekName = ["星期一", "星期二", "星期三", "星期四", "星期五"];
      let sortName = ["第1节", "第2节", "第3节", "第4节", "第5节", "第6节"];
      return function (name, value) {
        if (name == "weekName") {
          return weekName[value - 1];
        } else {
          return sortName[value - 1];
        }
      };
    },
  },
  methods: {
    exportCourse() {
      this.$postFile("/course/tables/exportCourseTables", { type: this.trainingCoursesSchedule ? 2 : 1 })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = this.trainingCoursesSchedule ? '个训课课表' : "小组课课表";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    getGroupSchedule() {
      const data = {};
      data.type = this.trainingCoursesSchedule ? 2 : 1;
      this.$postJson(`/course/tables/queryCourseTable`, data).then((res) => {
        if (this.trainingCoursesSchedule) {
          let weekList = [];
          for (let i = 1; i < 6; i++) {
            let dayArr = ["星期一", "星期二", "星期三", "星期四", "星期五"];
            let obj = {
              week: i,
              weekName: dayArr[i - 1],
              sortList: [],
            };
            weekList.push(obj);
            for (let j = 1; j < 7; j++) {
              let sortArr = [
                "第一节",
                "第二节",
                "第三节",
                "第四节",
                "第五节",
                "第六节",
              ];
              weekList[i - 1].sortList.push({
                sort: j,
                sortName: sortArr[j - 1],
                contentList: [],
              });
            }
          }

          weekList.forEach((item) => {
            item.sortList.forEach((jtem) => {
              res.data.forEach((ktem) => {
                if (ktem.isWeek == item.week && ktem.sort == jtem.sort) {
                  jtem.contentList.push(ktem);
                }
              });
            });
          });

          this.groupScheduleList = weekList;
          this.trainingCoursesList =
            this.groupScheduleList[this.week - 1].sortList[
              this.sort - 1
            ].contentList;
          console.log(this.groupScheduleList);
          console.log(this.trainingCoursesList);

          //   let weekList = [
          //     {
          //       week: 1,
          //       weekName: "星期一",
          //       sortList: [
          //         {
          //           sort: 1,
          //           sortName: "第一节",
          //           contentList: [],
          //         },
          //         {
          //           sort: 2,
          //           sortName: "第二节",
          //           contentList: [],
          //         },
          //       ],
          //     },
          //     {
          //       week: 2,
          //       weekName: "星期二",
          //       sortList: [
          //         {
          //           sort: 2,
          //           sortName: "第二节",
          //           contentList: [],
          //         },
          //       ],
          //     },
          //   ];
        } else {
          this.groupScheduleList = res.data;
        }
      });
    },
    arrowClick(n, name) {
      let weekList = ["星期一", "星期二", "星期三", "星期四", "星期五"];
      let sortList = [
        "第一节",
        "第二节",
        "第三节",
        "第四节",
        "第五节",
        "第六节",
      ];
      if (name == "week") {
        if (n == 1) {
          this.week = this.week == 5 ? this.week : this.week + n;
        } else if (n == -1) {
          this.week = this.week == 1 ? this.week : this.week + n;
        }
        this.weekName = weekList[this.week - 1];
      } else {
        if (n == 1) {
          this.sort = this.sort == 6 ? this.sort : this.sort + n;
        } else if (n == -1) {
          this.sort = this.sort == 1 ? this.sort : this.sort + n;
        }
        this.sortName = sortList[this.sort - 1];
      }

      this.trainingCoursesList =
        this.groupScheduleList[this.week - 1].sortList[
          this.sort - 1
        ].contentList;
    },
    queryCourseHourList() {
      this.$get(`/course/hour/queryCourseHourList/2`).then((res) => {
        this.courseHourList = res.data;
      });
    },
    //   新增个训课课表
    submitTrainingCourseTable() {
      const data = {
        isWeek: this.trainingCourseForm.isWeek,
        type: 2,
        sort: this.trainingCourseForm.sort,
        courseHourId: this.trainingCourseForm.name,
      };
      this.$postJson(`/course/tables/submitTrainingCourseTable`, data)
        .then((res) => {
          this.getGroupSchedule();
          this.trainingCourseFormDialogVisible = false;
          this.$message({
            type: "success",
            message: "添加成功！",
          });
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
        });
    },
    addTrainingCourse() {
      this.trainingCourseFormDialogVisible = true;
      this.$nextTick(() => {
        this.trainingCourseForm.isWeek = "";
        this.trainingCourseForm.sort = "";
        this.trainingCourseForm.name = "";
      });
    },
    clickShowDialog(name, item) {
      console.log(item);
      this[`${name}DialogVisible`] = true;
      this.$nextTick(() => {
        this[name].id = item.id;
        this[name].isWeek = item.isWeek;
        this[name].sort = item.sort;

        if (name == "classroomForm") {
          this.classroomForm.name = item.classroomId;
        } else if (name == "teacherForm") {
          this.teacherForm.mainTeachName = item.mainTeachId;

          this.teacherForm.assistName = item.assistTeachGroup
            .split(",")
            .map((item) => +item);

          // this.teacherForm.assistName = item.assistName;
        } else if (name == "courseForm") {
          this.courseForm.subject = item.courseId;
        } else if (name == "studentForm") {
          this.studentForm.student = item.studentGroup
            .split(",")
            .map((item) => +item);
        } else if (name == "remarkForm") {
          this.remarkForm.remark = item.remark;
        }

        console.log(this.studentForm.student);
      });
    },
    submit(name) {
      const data = {
        id: this[name].id,
        isWeek: this[name].isWeek,
        sort: this[name].sort,
      };
      if (name == "classroomForm") {
        data.classroomId = this.classroomForm.name;
      } else if (name == "teacherForm") {
        data.mainTeachId = this.teacherForm.mainTeachName;
        data.assistTeachGroup = this.teacherForm.assistName.join(",");
        // data.assistTeachGroup = this.teacherForm.assistName;
      } else if (name == "courseForm") {
        data.courseId = this.courseForm.subject;
        this.courseList.forEach((item) => {
          if (item.id == data.courseId) {
            data.courseName = item.course;
          }
        });
      } else if (name == "studentForm") {
        data.studentGroup = this.studentForm.student.join(",");
      } else if (name == "remarkForm") {
        data.remark = this.remarkForm.remark;
      }
      console.log(data.student);
      // return
      this.$postJson(`/course/tables/updateCourseTable`, data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "编辑成功！",
          });
          this.getGroupSchedule();
          this[`${name}DialogVisible`] = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
        });
    },
    // 查询教室
    loadClassroom() {
      this.$post("/base/queryClassroom.do")
        .then((res) => {
          this.classroomList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 查询学生
    loadStudent() {
      let data = { indexNo: -1 };
      this.$post(`/media/queryStudent.do`, data).then((res) => {
        this.studentList = res.data.rows;
      });
    },
    // 查询教师
    loadAllTeacher() {
      const that = this;
      // let data = { is_no: 1 }; //查询身份是否校内，1是校内
      this.$post("/media/queryAllManager.do")
        .then((res) => {
          that.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 学科查询
    queryCourse() {
      this.$postJson("/media/queryCourse.do").then((res) => {
        console.log(res);
        this.courseList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogHover:hover {
  cursor: pointer;
}
.dialogHover:focus {
  background-color: #acd5e9;
}

.weekAndSection {
  display: flex;
  justify-content: space-around;
}
.weekRowStyle {
  flex: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  height: 1px;
  line-height: 0px;
}
.iconArrowStyle {
  font-weight: 800;
  font-size: 20px;
}
.iconArrowStyle:hover {
  cursor: pointer;
  color: #00a1de;
}
</style>