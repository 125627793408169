<template>
  <div>
    <div class="flex-box marginBottom">
      <div>
        <el-button type="primary" @click="confirmPublic" icon="el-icon-printer"
          >确认发布</el-button
        >
        <el-button
          @click="exportTimetable"
          type="primary"
          icon="el-icon-download"
          >导出</el-button
        >
        <el-button
          @click="scheduleClassesImmediately"
          type="success"
          icon="el-icon-printer"
          >立即排课</el-button
        >
      </div>
      <div>
        <el-select
          clearable
          @change="selectDay"
          v-model="query.day"
          placeholder="下拉选择星期几"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          @click="queryTimetable"
          class="marginLeft"
          type="primary"
          icon="el-icon-printer"
          >查询</el-button
        >
      </div>
    </div>

    <div
      style="margin-bottom: 50px"
      :class="weekIndex == index || weekIndex == -1 ? 'show' : 'none'"
      v-for="(item, index) in templateData"
      :key="index"
    >
      <table
        cellspacing="0"
        border="1"
        cellpadding="25"
        width="1500px"
        class="table-box"
        v-if="item.classList[0]"
      >
        <!-- v-if="item.classList[0]" -->
        <thead>
          <tr>
            <th rowspan="2"></th>
            <th colspan="6" style="background-color:#E3EAF7;">
              <div class="weekRowStyle">
                <i
                  @click="arrowClick(index, -1)"
                  class="el-icon-arrow-left iconArrowStyle"
                ></i>
                <span style="font-size: 20px">{{ item.weekName }}</span>
                <i
                  @click="arrowClick(index, 1)"
                  class="el-icon-arrow-right iconArrowStyle"
                ></i>
              </div>
            </th>
          </tr>

          <!-- <tr>
            <th v-for="i in 6" :key="i">
              <div>第{{ i }}节</div>
              <div style="margin-top: 8px">
                {{
                  item.classList[0] && item.classList[0].courseList[i - 1]
                    ? `${item.classList[0].courseList[i - 1].startTime}~${
                        item.classList[0].courseList[i - 1].endTime
                      }`
                    : ""
                }}
              </div>
            </th>
          </tr> -->

          <tr>
            <th v-for="(item) in sortTimeList" :key="item.id">
              <div>第{{ item.sort }}节</div>
              <div style="margin-top: 8px">
                {{ item.startTime }} ~ {{ item.endTime }}
              </div>
            </th>
          </tr>

        </thead>

        <tbody>
          <tr v-for="(item2, index2) in item.classList" :key="index2">
            <td style="font-weight: bold">{{ item2.className }}</td>
            <td
              :tabindex="index3"
              class="bgStyle"
              @click="editCourseInfo(item, item2, item3, index)"
              v-for="(item3, index3) in item2.courseList"
              :key="index3"
            >
              <div style="color: black; font-weight: 550; font-size: 16px">
                {{ item3.courseName }}
              </div>
              <div v-if="item3.teacherName" style="color: #808080">主教：{{ item3.teacherName }}</div>
              <div v-if="item3.assistTeacherName" style="color: #808080">
                辅教：{{ item3.assistTeacherName }}
              </div>
            </td>

            <template v-if="item2.courseList.length < 6">
              <td v-for="i in 6 - item2.courseList.length" :key="i"></td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <el-dialog
      title="修改课表"
      :visible.sync="editCourseInfoDialogVisible"
      width="650px"
    >
      <div class="dialogStyle">
        <el-form
          :model="editTimeTableForm"
          :rules="editTimeTableFormRules"
          ref="editTimeTableForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="学科：" prop="subjectId">
            <el-select
              clearable
              v-model="editAllCourseTableForm.courseId"
              filterable
              placeholder="请选择学科"
            >
              <el-option
                v-for="item in subjectList"
                :key="item.id"
                :label="item.course"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="主教：" prop="teacherId">
            <el-select
              filterable
              clearable
              v-model="editAllCourseTableForm.mainTeachId"
              placeholder="请选择主教"
            >
              <el-option
                v-for="item in teacherList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="辅教：" prop="assistTeacherId">
            <el-select
              multiple
              filterable
              clearable
              v-model="editAllCourseTableForm.assistTeachGroup"
              placeholder="请选择辅教"
            >
              <el-option
                v-for="item in teacherList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editCourseInfoDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="timeTableConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "allSchoolTimetable",
  data() {
    return {
      schoolTimetableList: [],
      templateData: [],
      selectedDay: "",
      weekIndex: 0,
      // 学科课时
      subjectHourList: [],
      // 职位课时
      positionHourList: [],
      // 编辑课程信息表单
      editCourseInfoForm: {
        classCourseDetail: [],
      },
      // 编辑课程信息对话框显示与隐藏
      editCourseInfoDialogVisible: false,
      // 修改课表表单
      editTimeTableForm: {
        // grade:'',
        subjectId: "",
        subjectName: "",
        teacherId: "",
        teacherName: "",
        assistTeacherId: "",
        assistTeacherName: "",
      },
      // 修改课表表单验证规则对象
      editTimeTableFormRules: {
        // grade:[{required:true,message:'请选择年级',trigger:'blur'}],
        subjectId: [{ required: true, message: "请选择学科", trigger: "blur" }],
        teacherId: [{ required: true, message: "请选择主教", trigger: "blur" }],
        assistTeacherId: [
          { required: true, message: "请选择辅教", trigger: "blur" },
        ],
      },
      query: {
        day: "",
      },
      options: [
        { label: "全部", value: 0 },
        { label: "星期一", value: 1 },
        { label: "星期二", value: 2 },
        { label: "星期三", value: 3 },
        { label: "星期四", value: 4 },
        { label: "星期五", value: 5 },
      ],
      subjectList: [],
      teacherList: [],
      // 修改全校课表表单
      editAllCourseTableForm:{
        id: "",
        isWeek: "",
        sort: "",
        courseId:'',
        courseName:'',
        mainTeachId:'',
        assistTeachGroup:''
      },
      sortTimeList:[]
    };
  },
  created() {
    this.queryCourse();
    this.loadAllTeacher();

    this.getSortTime()
    this.getSchoolTimetable();
  },
  methods: {
    // 得到节次时间
    getSortTime(){
      this.$get(`/course/setting/queryCourseSetting`).then(res=>{
        console.log(res)
        this.sortTimeList = res.data
      })
    },
    getSchoolTimetable() {
      const data = { type: 0 };
      this.$postJson(`/course/tables/queryCourseTable`, data).then((res) => {
        this.schoolTimetableList = res.data;
        let templateData = [];
        for (let i = 1; i < 6; i++) {
          let dayArr = ["星期一", "星期二", "星期三", "星期四", "星期五"];
          let obj = {
            week: i,
            weekName: dayArr[i - 1],
            classList: [],
          };
          templateData.push(obj);
        }
        // this.templateData = templateData;
        // console.log(templateData);

        res.data.forEach((item) => {
          let index = templateData[item.isWeek - 1].classList.findIndex(
            (jtem) => item.classId === jtem.classId
          );
          if (index == -1) {
            templateData[item.isWeek - 1].classList.push({
              className: item.className,
              classId: item.classId,
              courseList: [
                {
                  id: item.id,
                  courseName: item.courseName,
                  courseId: item.courseId,
                  teacherName: item.mainTeachName,
                  assistTeacherName: item.assistName,
                  startTime: item.startTime.substr(0, 5),
                  endTime: item.endTime.substr(0, 5),
                },
              ],
            });
          } else {
            templateData[item.isWeek - 1].classList[index].courseList.push({
              id: item.id,
              courseName: item.courseName,
              courseId: item.courseId,
              teacherName: item.mainTeachName,
              assistTeacherName: item.assistName,
              startTime: item.startTime.substr(0, 5),
              endTime: item.endTime.substr(0, 5),
            });
          }
        });
        // console.log(this.templateData.length)
        if (res.data.length != 0) {
          this.$emit("activeNameChange");
        }
        this.templateData = templateData;
        //// console.log(this.templateData)
      });
    },
    // 选中星期
    selectDay(value) {
      this.selectedDay = value;
    },
    // 点击查询课表按钮
    queryTimetable() {
      this.weekIndex = this.selectedDay - 1;
    },
    // 点击箭头
    arrowClick(index, type) {
      this.weekIndex =
        type == 1 ? (index == 4 ? 4 : index + 1) : index == 0 ? 0 : index - 1;
    },
    // 学科查询
    queryCourse() {
      this.$postJson("/media/queryCourse.do").then((res) => {
        console.log(res);
        this.subjectList = res.data;
      });
    },
    // 查询教师
    loadAllTeacher() {
      const that = this;
      // let data = { is_no: 1 }; //查询身份是否校内，1是校内
      this.$post("/media/queryAllManager.do")
        .then((res) => {
          that.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 编辑课程信息
    editCourseInfo(item, item2, item3, index) {
      console.log(item)
      console.log(item2)
      console.log(item3)
      console.log(index)

      this.weekIndex = index;


      // this.editCourseInfoDialogVisible = true;
      this.editCourseInfoDialogVisible = item3.teacherName ? true :false
      let list = this.schoolTimetableList.filter((m) => m.id == item3.id);

      this.editAllCourseTableForm.id = list[0].id;
      this.editAllCourseTableForm.isWeek = list[0].isWeek;
      this.editAllCourseTableForm.sort = list[0].sort;
      this.editAllCourseTableForm.courseId = list[0].courseId;
      this.editAllCourseTableForm.courseName = list[0].courseName;
      this.editAllCourseTableForm.mainTeachId = list[0].mainTeachId;
      this.editAllCourseTableForm.assistTeachGroup =
        list[0].assistTeachGroup.split(",").map((item) => +item);
    },
    // 修改课表提交
    timeTableConfirm(){
      let courseName
      this.subjectList.forEach(item=>{
        if(item.id === this.editAllCourseTableForm.courseId){
          courseName = item.course
        }
      })
      const data = {
        id:this.editAllCourseTableForm.id,
        isWeek:this.editAllCourseTableForm.isWeek,
        sort:this.editAllCourseTableForm.sort,
        courseId:this.editAllCourseTableForm.courseId,
        courseName:courseName,
        mainTeachId:this.editAllCourseTableForm.mainTeachId,
        assistTeachGroup:this.editAllCourseTableForm.assistTeachGroup.join(','),
      }
      console.log(data)
      // return
      this.$postJson(`/course/tables/updateCourseTable`, data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "编辑成功！",
          });
          this.getSchoolTimetable();
          this.editCourseInfoDialogVisible = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
        });

    },
    // 确认发布
    confirmPublic() {
      console.log(this.schoolTimetableList);
      // return
      if (this.schoolTimetableList.length) {
        this.$postJson(`/class/course/saveCourse`).then((res) => {
          // console.log(res)
          this.$message({
            type: "success",
            message: "发布成功！",
          });
        });
      } else {
        this.$message({
          type: "info",
          message: "请先排课！",
        });
      }
    },
    // 导出课表
    exportTimetable() {
      const that = this;
      // /class/course/export
      this.$postFile("/course/tables/exportCourseTables", {type:0})
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "课表模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 立即排课
    scheduleClassesImmediately() {
      this.$confirm("是否立即排课?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          // /class/course/scheduleCourse
          this.$post(`/course/tables/startWorkCourse`)
            .then((res) => {
              this.getSchoolTimetable();
              loading.close();

              this.$message({
                type: "success",
                message: "排课成功!",
              });
              this.$emit('change',true)

            })
            .catch((err) => {
              loading.close();
              this.$message({
                type: "info",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消立即排课",
          });
        });
    },
  },
};
</script>

<style scoped>
.marginLeft {
  margin-left: 20px;
}

.marginBottom {
  margin-bottom: 20px;
}

.show {
  display: block;
}

.none {
  display: none;
}

.table-box {
  text-align: center;
  width: 100%;
}

.weekRowStyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  height: 1px;
  line-height: 0px;
}

.iconArrowStyle {
  font-weight: 800;
  font-size: 20px;
}

.iconArrowStyle:hover {
  cursor: pointer;
  color: #00a1de;
}

.bgStyle {
  cursor: pointer;
}

.bgStyle:focus {
  background-color: #acd5e9;
}

.dialogStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
