<template>
  <div>
    <el-button @click="exportTimetable(1)" type="primary" icon="el-icon-download"
      >导出课时统计表</el-button
    >
    <el-table :data="classScheduleList">
      <el-table-column prop="course" label="教师" align="center">
      </el-table-column>
      <el-table-column prop="job" label="教师职位" align="center">
      </el-table-column>
      <el-table-column
        prop="actualMainCount"
        label="实际主教节次"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="actualAssistCount"
        label="实际辅教节次"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="planHour" label="计划课时" align="center">
      </el-table-column>
      <el-table-column prop="actualHour" label="实际课时" align="center">
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev,pager,next"
      background
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="queryHourPageList"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "trainingCoursesSchedule",
  data() {
    return {
      classScheduleList: [],
      page: { pageCount: 0, currentPage: 1 },
    };
  },
  created() {
    this.queryHourPageList(1);
  },
  methods: {
    queryHourPageList(page) {
      const data = {
        indexNo: page,
        rows: 10,
      };
      this.$postJson(`/course/tables/queryHourPageList`, data).then((res) => {
        this.classScheduleList = res.data.data;
        // this.page.pageCount = (res.data.totalCount / 10) < 1 ? 1 : Math.ceil((res.data.totalCount / 10));
        // this.page.currentPage = page
      });
    },
    // 导出课时统计表
    exportTimetable(page) {
      const that = this;
      const data = {
        indexNo: page,
        rows: 10,
      };
      this.$postFile("/course/tables/ExportHour", data)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "课时统计表";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>